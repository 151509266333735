<template>
  <div class="map-popup">
    <h2 class="tert set-the-maximum">{{ service.name }}</h2>
    <v-icon v-if="isItFavourite(service._id)" class="favourite-icon" size="34px"
    color="amber darken-1" @click="clickOnActiveFavourite()">
      mdi-star
    </v-icon>
    <v-icon v-else class="favourite-icon" size="30px"
    color="tertiary" @click="clickOnInactiveFavourite()">
      mdi-star-plus-outline
    </v-icon>
    <div class="address-group mb-2">
      <v-icon small color="primary">mdi-map-marker-radius</v-icon>
      <span class="address-next-to-icon">
        <span>{{ getAddressPartOne() }}</span><br>
        <span>{{ getAddressPartTwo() }}</span>
      </span>
    </div>
    <v-tabs class="tabs" height="20px" v-model="tab">
      <v-tab class="opening-hours-tab"><strong>Nyitvatartás</strong></v-tab>
      <v-tab class="information-tab"><strong>Információ</strong></v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div class="opening-hours pt-1 mb-3">
          <div class="opening-hours-row" v-for="(day, index) in weekdays" :key="index">
            <div class="day"><strong>{{ day }}</strong></div>
            <div>{{ service.openingHours[index] ? `${service.openingHours[index].from} - ${service.openingHours[index].to}` : 'Zárva' }}</div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="details">
          <div class="favourite-details detail">
            <v-icon dense color="primary">mdi-phone</v-icon>
            <span class="ml-2">
              {{ service.contact.phone }}
            </span>
          </div>
          <div class="favourite-details detail">
            <v-icon dense color="primary">mdi-email-mark-as-unread</v-icon>
            <span class="ml-2">
              {{ service.contact.email }}
            </span>
          </div>
          <div class="favourite-details detail">
            <v-icon dense color="primary">mdi-web</v-icon>
            <v-btn x-small text class="button-with-link ml-2 px-0" @click="openLink">
              <span class="prim">Weboldal megtekintése</span>
              <v-icon class="pl-1" small color="primary">mdi-open-in-new</v-icon>
            </v-btn>
          </div>
          <div class="pt-1"><strong>Főbb szolgáltatások:</strong></div>
          <div class="detail serviceTypes">
            <v-chip class="serviceType" color="primary" outlined v-for="type in filteredMainServiceTypes" :key="type" small>{{ type }}</v-chip>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <div class="reserve-button" v-if="canReserve">
      <v-btn color="quaternary" style="color: white" @click="proceed">Foglalás</v-btn>
    </div>

    <v-dialog v-model="addFavouriteDialog" width="400">
      <v-card class="favourites-card">
        <v-card-title class="tertiary mb-3 px-4 pt-2 pb-2 favourites-card-title">Hozzáadod a kedvencekhez?
          <v-icon color="white" right>mdi-star-plus</v-icon>
        </v-card-title>
        <v-card-text class="pb-0 px-4">
          <h3 class="prim custom-service-name pb-2">{{ service.name }}</h3>
          <div class="favourite-details mb-2">
            <v-icon color="primary">mdi-map-marker-radius</v-icon>
            <span class="smaller-address ml-2">
              <span>{{ getAddressPartOne() }}</span><br>
              <span>{{ getAddressPartTwo() }}</span>
            </span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="tertiary" text @click="addFavouriteDialog = false">Mégse</v-btn>
          <v-btn color="tertiary" style="color: white" @click="addTheFavourite()">Hozzáad</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeFavouriteDialog" width="400">
      <v-card class="favourites-card">
        <v-card-title class="quinary mb-3 px-4 pt-2 pb-2 favourites-card-title">Eltávolítod a kedvencekből?
          <v-icon color="white" right>mdi-star-remove-outline</v-icon>
        </v-card-title>
        <v-card-text class="pb-0 px-4">
          <h3 class="prim custom-service-name pb-2">{{ service.name }}</h3>
          <div class="favourite-details mb-2">
            <v-icon color="primary">mdi-map-marker-radius</v-icon>
            <span class="smaller-address ml-2">
              <span>{{ getAddressPartOne() }}</span><br>
              <span>{{ getAddressPartTwo() }}</span>
            </span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="quinary" text @click="removeFavouriteDialog = false">Mégse</v-btn>
          <v-btn color="quinary" style="color: white" @click="removeTheFavourite()">Eltávolít</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="maxFavouritesDialog" width="400">
      <v-card>
        <v-card-title class="tertiary maximal-favourites-title mb-3 px-4 pt-2">Kedvencek
          <v-icon color="white" right>mdi-star-plus</v-icon>
        </v-card-title>
        <v-card-text class="pb-0 pl-4 pr-2 pt-1">
          <div class="favourite-details">
            <v-icon class="max-info-icon" dense color="primary">mdi-information-outline</v-icon>
            <div class="maximal-favourites-text pl-2">Maximum 5 autómosót tudsz hozzáadni a kedvencek listádhoz.</div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="tertiary" text @click="maxFavouritesDialog = false">Rendben</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  props: ['service'],
  data: () => ({
    tab: null,
    weekdays: ['Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat', 'Vasárnap'],
    mainServiceTypes: ['kulso', 'belso', 'karpit', 'motor'],
    commaPosition: 0,
    addFavouriteDialog: false,
    maxFavouritesDialog: false,
    removeFavouriteDialog: false
  }),
  computed: {
    ...mapState('service', ['serviceTypes']),
    ...mapState('car', ['cars']),
    ...mapState('auth', ['user']),

    filteredMainServiceTypes () {
      const types = []
      for (const type of this.mainServiceTypes) {
        if (this.service.services.includes(type)) {
          const storedType = this.serviceTypes.find(e => e.value === type)
          if (storedType) {
            types.push(storedType.text)
          }
        }
      }
      return types
    },
    canReserve () {
      return !['onkiszolgalo', 'erkezesi', 'egyeb'].includes(this.service.type)
    }
  },
  methods: {
    ...mapMutations(['setSelectedService']),
    ...mapActions('auth', ['saveFavourite', 'removeFavourite']),

    isItFavourite (serviceID) {
      if (this.user.favourites.length) {
        if (this.user.favourites.includes(serviceID)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    proceed () {
      this.setSelectedService(this.service)
      if (this.cars.length) {
        this.$router.push({ name: 'pre-reserve' })
      } else {
        this.$router.push({ name: 'cars' })
      }
    },
    openLink () {
      window.open(this.service.contact.webpage, '_blank')
    },
    getAddressPartOne () {
      this.commaPosition = this.service.contact.address.indexOf(',')
      return this.service.contact.address.slice(0, this.commaPosition + 1)
    },
    getAddressPartTwo () {
      return this.service.contact.address.slice(this.commaPosition + 2)
    },
    clickOnActiveFavourite () {
      this.removeFavouriteDialog = true
    },
    clickOnInactiveFavourite () {
      if (this.user.favourites.length !== 5) {
        this.addFavouriteDialog = true
      } else {
        this.maxFavouritesDialog = true
      }
    },
    addTheFavourite () {
      this.saveFavourite(this.service._id)
      this.addFavouriteDialog = false
    },
    removeTheFavourite () {
      this.removeFavourite(this.service._id)
      this.removeFavouriteDialog = false
    }
  }
}
</script>

<style lang="sass" scoped>
.v-tab
  font-size: 13px !important
  padding: 0 5px !important
.day
  width: 35px
.opening-hours
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-left: auto
  margin-right: auto
  padding-top: 10px
  &-row
    display: flex
    justify-content: space-between
    align-content: space-between
    width: 100%
.prim
  color: var(--v-primary-base)
.tert
  color: #097edd
.details
  padding-top: 1px
  padding-bottom: 13px
  padding-left: 5px
  .detail
    padding-top: 3px
.reserve-button
  display: flex
  justify-content: center
  align-items: center
.serviceType
  margin-right: 3px
  margin-top: 5px
.button-with-link
    text-transform: none !important
    letter-spacing: .01em !important
.v-btn.v-size--x-small
  font-size: 12px !important
.set-the-maximum
  max-width: 180px !important
.address-group
  padding-top: 2px
  display: flex
  justify-content: left
  align-items: center
  max-width: 180px !important
.favourite-icon
  position: absolute
  top: 25px
  right: 22px
.favourites-card
  .v-card__text
    line-height: 1.2 !important
  .v-btn
    font-size: 17px !important
.favourites-card-title
  font-size: 19px !important
  color: white
.favourite-details
  display: flex
  justify-content: left
  align-items: center
.custom-service-name
  font-size: 20px !important
.maximal-favourites-title
  font-size: 25px !important
  color: white
.maximal-favourites-text
  font-size: 17px !important
.max-info-icon
  position: relative
  bottom: 15px
.smaller-address
  font-size: 16px !important
.address-next-to-icon
  margin-left: 6px
</style>
