import client from '../../axios'

export default {
  state: {
    cars: []
  },
  actions: {
    async getCars ({ state }) {
      const { data } = await client.get('/cars')
      state.cars = data
    },
    async saveCar ({ state }, car) {
      const { data } = await client.post('/cars', car)
      state.cars.push(data)
    },
    editCar ({ state }, car) {
      client.put('/cars', car)
      const existingCar = state.cars.find(({ _id }) => _id === car._id)
      existingCar.type = car.type
      existingCar.model = car.model
      existingCar.plate = car.plate
    },
    removeCar ({ state }, car) {
      client.delete(`/cars/${car._id}`)
      const carToDisable = state.cars.find(({ _id }) => _id === car._id)
      carToDisable.status = 'deleted'
      /* Nem szedjük ki a state-ből, mert 'Foglalásaim' kiírásánál meg kell legyen
      state.cars.splice(state.cars.indexOf(car), 1)
      */
    }
  },
  getters: {
    getCarDisplayName: () => (car) => `${car.model} - ${car.plate}`,
    getCarModel: () => (car) => `${car.model}`,
    getCarPlate: () => (car) => `${car.plate}`,
    getCarType: () => (car) => `${car.type}`,
    getCarById: (state) => (id) => state.cars.find(car => car._id === id)
  },
  namespaced: true
}
