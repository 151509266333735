import client from '../../axios'

export default {
  state: {
    userExtraData: {}
  },
  actions: {
    /* nem kell ilyet használni, mert user-nél getUser() adja ezeket az adatokat
    async getUserData ({ state }) {
      const { data } = await client.get('/user')
      state.userExtraData = data
    },
    */
    saveUserData ({ state }, userAdditionalData) {
      client.put('/user', userAdditionalData)
      state.userExtraData.addressType = userAdditionalData.addressType
      state.userExtraData.billingName = userAdditionalData.billingName
      state.userExtraData.zip = userAdditionalData.zip
      state.userExtraData.city = userAdditionalData.city
      state.userExtraData.address = userAdditionalData.address
      state.userExtraData.phone = userAdditionalData.phone
      state.userExtraData.companyName = userAdditionalData.companyName
      state.userExtraData.taxNumber = userAdditionalData.taxNumber
      state.userExtraData.afa = userAdditionalData.afa
      state.userExtraData.completeProfile = true
    },
    async passwordReminder ({ state }, email) {
      const response = await client.post('/user', email)
      return response.data.message
    },
    async sendEmailWithCode ({ state }, data) {
      await client.post('/user/send-email-with-code', data)
    },
    async updatePassword ({ state }, data) {
      await client.put('/user/update-password', data)
    },
    async setDeleteProfile () {
      await client.put('/user/delete-profile')
    }
  },
  namespaced: true
}
