import client from '../../axios'

export default {
  state: {
    reservations: []
  },
  actions: {
    async getReservations ({ state }) {
      const { data } = await client.get('/reservations')
      state.reservations = data
    },
    async addReservation ({ state }, reservation) {
      const { data } = await client.post('/reservations', reservation)
      state.reservations.unshift(data)
    },
    cancelReservation ({ state }, reservation) {
      client.post(`/reservations/${reservation._id}/cancel`)
      state.reservations.splice(state.reservations.indexOf(reservation), 1)
    },
    setFeedback ({ state }, reservation) {
      client.put(`/reservations/${reservation._id}/feedback`)
      const whichReservation = state.reservations.find(({ _id }) => _id === reservation._id)
      whichReservation.hadFeedback = true
    }
  },
  namespaced: true
}
