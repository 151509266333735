<template>
  <v-container fluid>
    <!--
    <v-dialog v-model="show" persistent width="500">
      <v-form ref="form" @submit.prevent="save" lazy-validation>
        <v-card>
          <v-card-title class="tertiary mb-3 card-title">
            <div class="space low-height">Regisztráció utolsó </div>
            <div class="low-height"> </div>
            <div class="low-height">lépése</div>
            <v-icon color="white" right>mdi-file-edit</v-icon>
          </v-card-title>
          <v-card-text class="pb-0">
            <div class="message">
              <div>Az autómosók kötelesek számlát kiállítani az általuk nyújtott szolgáltatásokról,
              ezért kérjük adj meg számlázási nevet és címet, mivel ezek az autómosók
              számára szükséges információk.</div>
              <div>A későbbiekben bármikor módosíthatod az adataidat a profilodban.</div>
            </div>
            <v-radio-group v-model="addressType" mandatory row>
              <v-radio label="Személyes" color="primary" value="personal" @click="radioSet"></v-radio>
              <v-radio label="Céges" color="primary" value="company" @click="radioSet"></v-radio>
            </v-radio-group>
            <v-text-field v-if="addressType === 'personal'" ref="name" v-model="billingName" label="Név" hint="A számlázási név a valós neved kell legyen." required :rules="[rules.billingName]" :error-messages="errors.billingName"></v-text-field>
            <v-text-field v-if="addressType === 'company'" v-model="companyName" label="Cégnév" placeholder="Pl. Tuti Mosó Kft." required :rules="[rules.companyName]" :error-messages="errors.companyName"></v-text-field>
            <v-text-field v-if="addressType === 'company'" v-model="taxNumber" label="Adószám" placeholder="Pl. 12345678-2-08" required :rules="rules.taxNumber" :error-messages="errors.taxNumber"></v-text-field>
            <v-text-field ref="zip" v-model="zip" label="Irányítószám" placeholder="Pl. 9026" required :rules="rules.zip" :error-messages="errors.zip"></v-text-field>
            <v-text-field ref="city" v-model="city" label="Település" placeholder="Pl. Győr" required :rules="[rules.city]" :error-messages="errors.city"></v-text-field>
            <v-text-field ref="address" v-model="address" label="Utca, házszám" placeholder="Pl. Mosós utca 11/B" required :rules="[rules.address]" :error-messages="errors.address"></v-text-field>
            <v-text-field v-model="phone" label="Telefonszám (opcionális)" placeholder="Pl. 06301234567" :rules="[rules.phone]" :error-messages="errors.phone"></v-text-field>
            <v-checkbox v-model="terms" color="primary" required :rules="[rules.terms]" :error-messages="errors.terms">
              <template v-slot:label>
                  <div @click.stop="">
                    Elfogadom az <a href="#" @click.prevent="openASzF()">ÁSzF</a>
                    <v-icon class="small-icon" size="14px" color="primary">mdi-open-in-new</v-icon>-ben és az
                    <a href="#" @click.prevent="openAdatkezelesi()">Adatkezelési szabályzat</a>
                    <v-icon class="small-icon" size="14px" color="primary">mdi-open-in-new</v-icon>-ban leírtakat.
                  </div>
              </template>
            </v-checkbox>
            <v-checkbox v-model="afa" color="primary" class="pt-0 mt-0">
              <template v-slot:label>
                  <div @click.stop="">
                    Szeretnék ÁFÁ-s számlát kérni az autómosóktól.
                  </div>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions class="pr-4">
            <v-spacer></v-spacer>
            <v-btn class="mb-2" color="tertiary" style="color: white" type="submit" :disabled="!formIsValid" @click="setShow()">
              Tovább a főoldalra
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    -->

    <v-dialog v-model="profileIsDeleting" persistent width="500">
      <v-card>
        <v-card-title class="quinary mb-3 card-title">Törlési folyamat
        </v-card-title>
        <v-card-text>
          Profilod törlését kérted, ezért nem tudod már használni az alkalmazás funkcióit. 30 napig van lehetőséged a törlést visszavonni,
           ezt követően viszont a profilod véglegesen törlésre kerül. A visszavonáshoz vedd fel velünk a kapcsolatot.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="quinary" style="color: white" @click="logoutDueToProfileDeletion">Rendben</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showFeedback" width="520">
      <v-form ref="submitform" v-model="submitform" @submit.prevent="sendFb">
        <v-card>
          <v-card-title class="tertiary mb-3 px-4 pt-2 pb-2 feedback-title">
            <div class="space low-height">Mondd el nekünk </div>
            <div class="low-height"> </div>
            <div class="low-height">a véleményedet
            </div>
            <v-icon color="white" right>mdi-comment-text-multiple</v-icon>
          </v-card-title>
          <v-card-text class="pb-0 px-4">
            <div class="feedback-message">
              <div class="pt-1">Minden rendben zajlott a legutóbbi foglalásod során?
              Tapasztaltál bármit, amivel nem voltál elégedett? </div>
              <div>Tetszik az alkalmazás, szívesen ajánlanád az ismerőseidnek is? </div>
              <div>Kérjük mondd el a véleményedet!</div>
            </div>
            <hr style="height:9px; visibility:hidden;"/>
            <v-divider></v-divider>
            <hr style="height:9px; visibility:hidden;"/>
            <div>
              <span class="primary-color">Itt foglaltál:</span>
              {{ whichService() }}
            </div>
            <div>
              <span class="primary-color">Ekkor:</span>
              {{ whichDate() }}
            </div>
            <div>
              <div class="primary-color space">
                <span class="space">Értékeld a foglalásodat: </span>
                {{ rating }}
                <span>/ 5</span>
              </div>
              <v-rating v-model="rating" half-increments hover color="orange"
              background-color="orange lighten-3" large></v-rating>
            </div>
            <v-textarea
              v-model="textareaValue"
              outlined
              auto-grow
              rows="4"
              row-height="20"
              counter="250"
              label="Írj üzenetet"
              :rules="textareaRule"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="tertiary" text @click="closeFeedback()">Bezár</v-btn>
            <v-btn color="tertiary" style="color: white" type="submit" :disabled="!submitform" @click="closeFeedback()">
              Küldés
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <div class="search">
      <v-text-field clearable placeholder="Település neve" prepend-inner-icon="mdi-magnify"
      :error-messages="searchErrors" v-on:keyup.enter="search" @input="searchErrors = []" v-model="searchText" />
      <v-btn class="ml-2 px-1" color="tertiary" style="color: white" @click="search">Keresés</v-btn>
      <v-dialog scrollable v-model="filterDialog" width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-1" color="tertiary" style="color: white" v-bind="attrs" v-on="on">
            <v-icon>mdi-filter-cog</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="tertiary px-4 py-2 card-title filter-title">Szolgáltatás szűrő
            <v-icon class="pl-1" color="white" right>mdi-text-search</v-icon>
          </v-card-title>
          <v-card-text class="pb-1">
            <hr style="height:5px; visibility:hidden;"/>
            <v-checkbox hide-details class="my-2 checkbox" label="Nincs szűrés" v-model="noFilter"/>
            <div class="checkboxes">
              <v-checkbox hide-details class="my-2 checkbox" v-for="type in serviceTypes" :key="type.value" :label="type.text" v-model="selectedServiceTypes[type.value]"/>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="tertiary" text @click="filterDialog = false">Alkalmaz</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="map-wrapper">
      <l-map ref="map" :center="mapOptions.center" :zoom="mapOptions.zoom" style="height: 60vh" no-blocking-animations>
        <l-tile-layer :url="mapOptions.tileLayer.url" :subdomains="mapOptions.tileLayer.subdomains" />
        <l-control position="bottomleft">
          <div class="legend">
            <h3 class="px-4 py-1">Színmagyarázat</h3>
            <div class="legend-row">
              <img class="pin-icon" src="/img/pins/red-pin.png" width="15px" /><div>Foglalós</div>
            </div>
            <div class="legend-row">
              <img class="pin-icon" src="/img/pins/blue-pin.png" width="15px" /><div>Önkiszolgáló</div>
            </div>
            <!--
            <div class="legend-row">
              <img class="pin-icon" src="/img/pins/green-pin.png" width="15px" /><div>Érkezési sorrendes</div>
            </div>
            -->
            <div class="legend-row">
              <img class="pin-icon" src="/img/pins/yellow-pin.png" width="15px" /><div>Automata</div>
            </div>
            <div class="legend-row">
              <img class="pin-icon" src="/img/pins/green-pin.png" width="15px" /><div>Egyéb szolgáltatás</div>
            </div>
          </div>
        </l-control>
        <l-marker v-for="service in filteredServices" :key="service.name" :lat-lng="service.location" @mouseup="pinClicked(service._id)">
          <l-icon :icon-url="getIconUrl(service.type)" :popup-anchor="[0, -20]" shadow-url="https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png" />
          <l-popup>
            <map-popup :id="service._id" :service="service" />
          </l-popup>
        </l-marker>
      </l-map>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import { clone as _clone, pickBy as _pickBy } from 'lodash-es'
import MapPopup from '../components/MapPopup.vue'
import moment from 'moment'
import client from '../axios'

export default {
  components: { MapPopup },
  data: () => ({
    showFeedback: false,
    profileIsDeleting: false,
    /*
    show: false,
    addressType: 'personal',
    billingName: '',
    zip: null,
    city: null,
    address: null,
    phone: '',
    companyName: '',
    taxNumber: '',
    terms: false,
    afa: false,
    */
    searchText: '',
    searchErrors: [],
    mapOptions: {
      center: [47, 19],
      zoom: 7,
      tileLayer: {
        url: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=hu',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      }
    },
    filterDialog: false,
    selectedServiceTypes: {},
    /*
    rules: {
      billingName: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
      zip: [
        v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
        v => /^[0-9]*$/.test(v) || 'Kérjük adj meg valós irányítószámot.'
      ],
      city: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
      address: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
      phone: v => /^[0-9\s+-]*$/.test(v) || 'Kérjük adj meg valós telefonszámot.',
      companyName: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
      taxNumber: [
        v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
        v => /^[0-9-]*$/.test(v) || 'Kérjük adj meg szabályos adószámot.'
      ],
      terms: v => !!v || 'Kérjük az elolvasásuk után fogadd el az ÁSzF-et és az Adatkezelési szabályzatot.'
    },
    */
    textareaRule: [v => v.length <= 250 || 'Kérjük maximum 250 karakterben fogalmazd meg az üzenetedet.'],
    // errors: {},
    feedbackReservation: null,
    rating: 4.5,
    textareaValue: '',
    submitform: false,
    id: ''
  }),

  computed: {
    ...mapState('service', ['services', 'serviceTypes']),
    ...mapState('auth', ['user']),
    ...mapState('reservation', ['reservations']),
    /* ...mapState('userData', ['userExtraData']), mégsem kell használni */

    /*
    formIsValid () {
      return (
        (this.addressType === 'personal' && this.billingName && this.zip && this.city && this.address && this.terms) ||
        (this.addressType === 'company' && this.companyName && this.zip && this.city && this.address && this.taxNumber && this.terms)
      )
    },
    */
    /*
    displayName: {
      get () {
        this.setName(this.user.name)
        return this.user.name
      },
      set (value) {
        this.billingName = value
      }
    },
    */
    checkedFilters () {
      return _pickBy(this.selectedServiceTypes, (value) => value === true)
    },
    noFiltersChecked () {
      return !Object.keys(this.checkedFilters).length
    },
    filteredServices () {
      let filtered = _clone(this.services)
      if (!this.noFiltersChecked) {
        filtered = filtered.filter(service => {
          let hasAllType = true
          for (const type in this.checkedFilters) {
            if (!service.services.includes(type)) {
              hasAllType = false
              break
            }
          }
          return hasAllType
        })
      }

      return filtered
    },
    noFilter: {
      get () {
        return this.noFiltersChecked
      },
      set (value) {
        if (value) {
          this.uncheckAllFilters()
        }
      }
    }
  },

  async mounted () {
    /*
    await this.getUser() // lekérjük user-t, be kell várni a legelső adat-megadás utáni mount-nál

    if (this.user.completeProfile) {
      this.show = false
    } else {
      this.show = true
    }
    */

    if (this.user.deleteProfile) {
      this.profileIsDeleting = true
    } else {
      this.checkFeedback()
    }

    this.resetSelected()
    this.uncheckAllFilters()
    /*
    var something = this.displayName
    if (something === 'nothing') {}
    */
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
        this.$refs.map.mapObject.setView([latitude, longitude], 14)
      }, () => console.log('position not available'), { enableHighAccuracy: true })
    }
  },

  methods: {
    ...mapMutations(['setSelectedService']),
    /*
    ...mapActions('userData', ['saveUserData']),
    ...mapActions('auth', ['getUser']),
    */
    ...mapActions('reservation', ['setFeedback']),

    async logoutDueToProfileDeletion () {
      await this.$store.dispatch('auth/logout')
      this.$router.replace({ name: 'login' })
    },
    async checkFeedback () {
      for (const which of this.reservations) {
        if (which.hadFeedback === false) {
          if (which.status === 'accepted' && which.fulfilled === true) {
            if (moment(which.date).add(which.duration, 'minutes').isBefore(moment())) {
              this.feedbackReservation = which
              this.showFeedback = true
              await this.setFeedback(which)
              break
            }
          }
        }
      }
    },
    whichService () {
      if (this.feedbackReservation !== null) {
        const id = this.feedbackReservation.service
        const thisService = this.services.find(({ _id }) => _id === id)
        return thisService.name
      }
    },
    whichDate () {
      if (this.feedbackReservation !== null) {
        return `${moment(this.feedbackReservation.date).format('YYYY-MM-DD HH:mm')}-${moment(this.feedbackReservation.date).add(this.feedbackReservation.duration, 'minutes').format('HH:mm')}`
      }
    },
    closeFeedback () {
      this.showFeedback = false
    },
    sendFb () {
      const idD = this.feedbackReservation.service
      const thisService = this.services.find(({ _id }) => _id === idD)

      const feedback = { rating: this.rating, message: this.textareaValue, service: thisService.name, date: this.whichDate() }
      client.post('/feedback', feedback)
    },
    /*
    setShow () {
      this.show = false
    },
    */
    resetSelected () {
      this.setSelectedService(null)
    },
    /*
    setName (userName) {
      this.billingName = userName
    },
    */
    uncheckAllFilters () {
      for (const type of this.serviceTypes) {
        Vue.set(this.selectedServiceTypes, type.value, false)
      }
    },
    async search () {
      if (!this.searchText) {
        this.searchErrors = ['Kérjük írd be egy település nevét.']
        return
      }
      const { data } = await axios.get(`https://nominatim.openstreetmap.org/search?q=${this.searchText}&format=json&accept-language=hu-HU`)
      if (data && data.length) {
        const { lat, lon } = data[0]
        this.$refs.map.mapObject.setView([lat, lon], 12)
      } else {
        this.searchErrors = ['Nincs találat.']
      }
    },
    getIconUrl (type) {
      let color
      switch (type) {
        case 'onkiszolgalo':
          color = 'blue'
          break
        case 'foglalos':
          color = 'red'
          break
        case 'erkezesi':
          color = 'yellow'
          break
        case 'egyeb':
          color = 'green'
          break
      }
      return `/img/pins/${color}-pin.png`
    },
    pinClicked (id) {
      setTimeout(() => {
        const informationTab = document.getElementById(id).querySelector('.information-tab')
        const openingHoursTab = document.getElementById(id).querySelector('.opening-hours-tab')
        setTimeout(() => informationTab.click(), 20)
        setTimeout(() => openingHoursTab.click(), 20)
      }, 50)
    }
    /*
    radioSet () {
      if (this.addressType === 'personal') {
        this.companyName = ''
        this.taxNumber = ''
      }
    }
    */
    /*
    save () {
      this.errors = {}
      if (!this.$refs.form.validate()) return
      const userAdditionalData = {
        addressType: this.addressType,
        billingName: this.billingName,
        zip: this.zip,
        city: this.city,
        address: this.address,
        phone: this.phone,
        companyName: this.companyName,
        taxNumber: this.taxNumber,
        afa: this.afa
      }
      if (userAdditionalData.addressType === 'company') {
        userAdditionalData.billingName = ''
      }
      this.saveUserData(userAdditionalData)

      // Nem ezt a módszert használjuk, hanem async mounted()-et
      // setTimeout(() => {
      //   this.getUser()
      // }, 2000)

    },
    */
    /*
    openASzF () {
      window.open('https://www.google.com', '_blank')
    },
    openAdatkezelesi () {
      window.open('https://www.google.com', '_blank')
    }
    */
  }
}
</script>

<style lang="sass" scoped>
.card-title
  color: white
.feedback-title
  color: white
  font-size: 27px !important
  margin-bottom: 8px !important
.space
  white-space: pre
.primary-color
  color: var(--v-primary-base)
.message
  font-size: 18px
.feedback-message
  font-size: 17px
  line-height: 120%
.low-height
  line-height: 130%
.search
  display: flex
  align-items: center
  padding-bottom: 3px
.checkboxes
  display: flex
  flex-wrap: wrap
  align-content: flex-start
.checkbox
  width: 50%
.legend
  width: 138px
  height: 140px
  background: rgba(255, 255, 255, 0.8)
  .legend-row
    padding-left: 6px
    padding-bottom: 2px
    display: flex
    align-items: center
    .pin-icon
      margin-right: 6px
.filter-title
  font-size: 21px !important
.small-icon
  padding-left: 2px
@media only screen and (min-width: 601px)
  .message *
    display: inline
  .feedback-title
    padding-bottom: 15px !important
    padding-top: 15px !important
</style>
