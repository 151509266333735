<template>
  <v-container fluid v-if="cars">
    <h3 class="title mb-5 mt-2">{{ `Válaszd ki ${cars.length > 1 ? 'az autóidat és a kért szolgáltatásokat!' : 'a kért szolgáltatásokat!'}` }}</h3>
      <v-expansion-panels v-if="cars.length > 1" v-model="selectedCarIndexes" multiple focusable>
        <v-expansion-panel v-for="car in cars" :key="car._id">
          <div v-if="car.status === 'active'">
            <v-expansion-panel-header class="primary-color">
              <template v-slot:actions>
                <v-icon color="primary">
                    $expand
                </v-icon>
              </template>
              <span class="car-display-name">
                <v-switch inset class="car-checkbox my-0" v-model="selectedCars[car._id]" @change="toggleCar(car._id, $event)" :disabled="disabled" hide-details/>
                <span>
                  <span class="primary-color">{{ getCarModel(car) }}</span><br>
                  <span class="smaller-plate its-grey">{{ getCarPlate(car) }}</span>
                </span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-3">
              <div class="services" v-if="serviceTypes.length">
                <span v-for="service in selectedService.services" :key="service" class="services mr-4">
                  <v-switch class="service-checkbox mr-1 my-0" :disabled="disabled" @change="toggleService(car._id, service, $event)" hide-details/>
                  <span class="smaller-service">{{ getServiceTypeName(service) }}</span><span> </span>
                </span>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels v-else v-model="selectedCarIndexes" multiple focusable>
        <v-expansion-panel v-for="car in cars" :key="car._id">
          <div>{{ setOneCarIndex() }}</div>
          <div v-if="car.status === 'active'">
            <v-expansion-panel-header class="primary-color">
              <template v-slot:actions>
                <v-icon color="primary">
                    $expand
                </v-icon>
              </template>
              <span class="car-display-name">
                <v-switch inset class="car-checkbox my-0" v-model="selectedCars[car._id]" @change="toggleCar(car._id, $event)" disabled hide-details/>
                <span>
                  <span class="primary-color">{{ getCarModel(car) }}</span><br>
                  <span class="smaller-plate its-grey">{{ getCarPlate(car) }}</span>
                </span>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-3">
              <div class="services" v-if="serviceTypes.length">
                <span v-for="service in selectedService.services" :key="service" class="services mr-4">
                  <v-switch class="service-checkbox mr-1 my-0" :disabled="disabled" @change="toggleService(car._id, service, $event)" hide-details/>
                  <span class="smaller-service">{{ getServiceTypeName(service) }}</span><span> </span>
                </span>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="proceed-button">
        <v-btn class="mt-5 mx-auto mb-2" color="quaternary" style="color: white" @click="proceed">Időpont kiválasztása</v-btn>
      </div>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { set as _set } from 'lodash-es'

export default {
  data: () => ({
    selectedCarIndexes: [],
    selectedServices: {}
  }),
  computed: {
    ...mapState('car', ['cars']),
    ...mapState(['selectedService']),
    ...mapState('service', ['serviceTypes']),
    ...mapGetters('service', ['getServiceTypeName']),
    ...mapGetters('car', ['getCarModel', 'getCarPlate', 'getCarType']),

    selectedCars () {
      const result = {}
      this.selectedCarIndexes.forEach(index => {
        const { _id } = this.cars[index]
        result[_id] = true
      })
      return result
    },
    disabled () {
      const disabled = this.selectedCarIndexes.length >= 4
      if (disabled) {
        this.setNotification('Maximum 3 autót választhatsz ki.')
      }
      return disabled
    }
  },
  methods: {
    ...mapMutations(['setNotification', 'setSelectedCars']),

    setOneCarIndex () {
      this.selectedCarIndexes[0] = 0
    },
    isCarSelected (index) {
      this.selectedCarIndexes.includes(index)
    },
    toggleService (carId, serviceType, value) {
      if (value) {
        _set(this.selectedServices, `${carId}.${serviceType}`, true)
      } else {
        if (this.selectedServices[carId]) {
          delete this.selectedServices[carId][serviceType]
          if (!Object.keys(this.selectedServices[carId]).length) {
            delete this.selectedServices[carId]
          }
        }
      }
    },
    toggleCar (carId, value) {
      if (!value) {
        delete this.selectedServices[carId]
      }
    },
    proceed () {
      if (!this.selectedCarIndexes.length) {
        this.setNotification('Még nem választottál ki egy autót sem.')
        return
      }

      if (this.disabled) {
        this.setNotification('Maximum 3 autót választhatsz ki.')
        return
      }

      for (const index of this.selectedCarIndexes) {
        const car = this.cars[index]
        if (!this.selectedServices[car._id] || !Object.keys(this.selectedServices[car._id]).length) {
          this.setNotification(`Még nem választottál szolgáltatást ehhez az autódhoz: <br>${this.getCarModel(car)} (${this.getCarPlate(car)})`)
          return
        }
      }

      this.setSelectedCars(this.selectedServices)
      this.$router.push({ name: 'calendar' })
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  color: var(--v-primary-base)
  line-height: 1.5rem
.primary-color
  color: var(--v-primary-base)
.its-grey
  color: grey
.services
  margin-left: 8px
  display: flex
  flex-wrap: wrap
  align-content: flex-start
  align-items: center
.proceed-button
  display: flex
  justify-content: center
.car-display-name
  display: flex
  justify-content: left
  align-items: center
.smaller-plate
  font-size: 16px
.smaller-service
  font-size: 18px
</style>
