<template>
  <v-container class="container" fluid v-if="Array.isArray(cars)">
    <v-dialog v-model="addCarDialog" width="500" eager>
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="save" lazy-validation>
          <v-card-title class="tertiary mb-3 card-title">{{ isEdit ? 'Autó módosítása' : 'Autó hozzáadása' }}
            <v-icon color="white" right>mdi-car-cog</v-icon>
          </v-card-title>
          <v-card-text>
            <v-text-field :rules="[rules.model]" label="Márka és modell" placeholder="Pl. Ford Focus" :error-messages="errors.model" v-model="model" required></v-text-field>
            <v-text-field :rules="rules.plate" label="Rendszám" placeholder="Pl. ABC-123" :error-messages="errors.plate" v-model="plate" required></v-text-field>
            <div class="type-select">
              <v-select :rules="[rules.type]" :items="types" v-model="type" :error-messages="errors.type" label="Típus" required></v-select>
              <v-tooltip bottom max-width="240px" transition="scale-transition" color="grey darken-4" content-class='custom-tooltip'>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small text color="primary">
                    <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <v-icon color="white" small left>mdi-information-outline</v-icon>
                <span>A típus meghatározása fontos információ a mosók számára, hogy az időt és költségeket tudják tervezni. A későbbiekben pedig a program is ezzel fog számolni, amivel pontosabb adatokat tudunk nektek adni a foglalásnál.</span>
              </v-tooltip>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="tertiary" text type="submit">{{ isEdit ? 'Módosítás' : 'Hozzáadás' }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeConfirmation" width="500">
      <v-card>
        <v-card-title class="quinary mb-3 card-title">Biztosan törlöd?
          <v-icon color="white" right>mdi-car-off</v-icon>
        </v-card-title>
        <v-card-text>
          Az alábbi autód lesz törölve:<br>
          <div class="remove-car-display-name py-2">
            <v-icon color="primary" left>mdi-car</v-icon>
            <span class="ml-1">
              <span class="primary-color">{{ getCarModel(carToRemove) }}</span><br>
              <span class="smaller-plate its-grey">{{ getCarPlate(carToRemove) }}</span>
            </span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="quinary" text @click="removeConfirmation = false">Mégse</v-btn>
          <v-btn color="quinary" style="color: white" @click="remove">Törlés</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="no-cars" v-if="!cars.length">
      <div class="label">Nincs még felvéve egy autód sem.</div>
      <v-btn color="tertiary" style="color: white" class="mt-5" @click="add">
        <v-icon size="25px" left>mdi-plus</v-icon>
        Új autó hozzáadása
      </v-btn>
    </div>
    <div class="cars" v-else>
      <v-list>
        <v-list-item-group color="primary">
          <div v-for="car in cars" :key="car._id">
            <v-list-item v-if="car.status === 'active'">
              <v-list-item-title>
                <div class="car-display-name py-2">
                  <v-icon color="primary" left>mdi-car</v-icon>
                  <span class="ml-1">
                    <span class="primary-color">{{ getCarModel(car) }}</span><br>
                    <span class="smaller-plate its-grey">{{ getCarPlate(car) }}</span>
                  </span>
                </div>
                <v-tooltip :disabled="isEditable(car)" bottom max-width="220px" transition="scale-transition" color="grey darken-4" content-class='custom-tooltip'>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="actions">
                      <v-icon v-on="on" v-bind="attrs" class="mr-3" color="primary" @click="edit(car)">mdi-pencil-outline</v-icon>
                      <v-icon v-on="on" v-bind="attrs" color="quinary" @click="removeConfirm(car)">mdi-delete-outline</v-icon>
                    </span>
                  </template>
                  <v-icon color="white" small left>mdi-information-outline</v-icon>
                  <span v-if="!isEditable(car)">Ezt az autót jelenleg nem tudod szerkeszteni vagy törölni, mert
                  van már folyamatban lévő foglalásod vele.
                  </span>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="car.status === 'active'"></v-divider>
          </div>
        </v-list-item-group>
      </v-list>
      <v-btn v-if="cars.length < 8" color="tertiary" style="color: white" class="mt-5 mb-3" @click="add">
        <v-icon size="25px" left>mdi-plus</v-icon>
        Új autó hozzáadása
      </v-btn>
      <div v-else>
        <v-btn v-if="notMaximalCarsYet()" color="tertiary" style="color: white" class="mt-5 mb-3" @click="add">
          <v-icon size="25px" left>mdi-plus</v-icon>
          Új autó hozzáadása
        </v-btn>
        <div v-else class="primary-color horizontally-middle pt-3">
          <div class="maximal-cars mx-6 mb-3">
            <v-icon color="primary" dense left>mdi-information-outline</v-icon>
            Maximum 8 autót tudsz felvenni.
          </div>
        </div>
      </div>
      <div class="proceed" v-if="selectedService">
        <v-btn color="quaternary" style="color: white" class="mt-2 mb-2" :to="{ name: 'pre-reserve' }">Tovább a foglaláshoz</v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  data: () => ({
    addCarDialog: false,
    removeConfirmation: false,
    carToRemove: {},
    isEdit: false,
    valid: true,
    model: '',
    plate: '',
    id: '',
    type: null,
    types: [
      { value: 'szemelyauto', text: 'Személyautó' },
      { value: 'varositerepjaro', text: 'Városi terepjáró' },
      { value: 'nagyterepjaro', text: 'Nagy terepjáró' },
      { value: 'teherauto', text: 'Teherautó' }
    ],
    rules: {
      model: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
      plate: [
        v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
        v => /^[A-Za-z0-9\s-]*$/.test(v) || 'Kérjük adj meg egy szabályos rendszámot.'
      ],
      type: v => !!v || 'Kérjük töltsd ki ezt a mezőt.'
    },
    errors: {}
  }),
  computed: {
    ...mapState(['selectedService']),
    ...mapState('car', ['cars']),
    ...mapGetters('car', ['getCarModel', 'getCarPlate', 'getCarType']),
    ...mapState('reservation', ['reservations'])

  },
  methods: {
    ...mapActions('car', ['saveCar', 'editCar', 'removeCar']),

    isEditable (thiscar) { /* Vizsgáljuk meg, lehet-e az adott kocsit törölni vagy módosítani */
      var isEditable = true
      if (this.reservations.length) {
        // var dontSkip = true
        Object.values(this.reservations).some(reservation => { /* végig megyünk a foglalásokon */
          // if (dontSkip) {
          if (moment(reservation.date).isSameOrAfter(moment())) { /* csak az releváns, ha Aktív még a foglalás */
            if (reservation.status === 'accepted' || reservation.status === 'pending') { /* és ha Elfogadott vagy Függőben */
              const carsArray = Object.getOwnPropertyNames(reservation.cars)
              for (let i = 0; i < carsArray.length - 1; i++) { /* az adott foglalás autóin iterálunk */
                if (thiscar._id === carsArray[i]) { /* ha a vizsgált autó is szerepel itt, akkor megvagyunk */
                  isEditable = false
                  // dontSkip = false
                  break
                }
              }
            }
          }
          // }
        })
      }
      return isEditable
    },
    notMaximalCarsYet () {
      let activeCount = 0
      for (const car of this.cars) {
        if (car.status === 'active') {
          activeCount++
        }
      }
      if (activeCount < 8) {
        return true
      } else {
        return false
      }
    },
    save () {
      this.errors = {}
      if (!this.$refs.form.validate()) return
      const car = {
        model: this.model,
        plate: this.plate,
        type: this.type,
        _id: this.id
      }
      if (this.isEdit) {
        this.editCar(car)
      } else {
        this.saveCar(car)
      }
      this.addCarDialog = false
    },
    add () {
      this.$refs.form.reset()
      this.isEdit = false
      this.addCarDialog = true
    },
    edit (car) {
      if (this.isEditable(car)) {
        this.id = car._id
        this.model = car.model
        this.type = car.type
        this.plate = car.plate
        this.isEdit = true
        this.addCarDialog = true
      }
    },
    remove () {
      this.removeCar(this.carToRemove)
      this.removeConfirmation = false
    },
    removeConfirm (car) {
      if (this.isEditable(car)) {
        this.carToRemove = car
        this.removeConfirmation = true
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  height: 100%
.no-cars
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
  color: var(--v-primary-base)
.primary-color
  color: var(--v-primary-base)
.its-grey
  color: grey
.right-icon
   position: absolute
   right: 10px
.v-list-item__title
  display: flex
  justify-content: space-between
.car-display-name
  width: calc(100vw - 100px)
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  display: flex
  justify-content: left
  align-items: center
.remove-car-display-name
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  display: flex
  justify-content: left
  align-items: center
.smaller-plate
  font-size: 18px
.not-visible
  display: none
.type-select
  display: flex
  align-items: center
.custom-tooltip
  opacity: 1!important
  font-size: 13px
.horizontally-middle
  display: flex
  justify-content: center
.maximal-cars
  text-align: center
  font-size: 17px
</style>
