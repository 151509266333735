import client from '../../axios'

export default {
  state: {
    services: [],
    serviceTypes: []
  },
  actions: {
    async getServices ({ state }) {
      const { data } = await client.get('/services')
      state.services = data
    },
    async getServiceTypes ({ state }) {
      const { data } = await client.get('/serviceTypes')
      state.serviceTypes = data
    }
  },
  getters: {
    getServiceTypeName: (state) => (type) => {
      if (!state.serviceTypes.length) return ''
      return state.serviceTypes.find(({ value }) => value === type).text
    }
  },
  namespaced: true
}
