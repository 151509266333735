<template>
  <v-app>
    <v-app-bar color="secondary" app>
      <v-app-bar-nav-icon v-if="$store.state.auth.isLoggedIn" color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <h4 class="navigation-title ml-4">{{ $route.meta.title }}</h4>
    </v-app-bar>

    <v-dialog v-model="removeProfileConfirmation" width="500">
      <v-card>
        <v-card-title class="quinary mb-3 card-title">Biztosan törölni szeretnéd?
        </v-card-title>
        <v-card-text>
          Profilod törlési kérelmét 30 napig tudod visszavonni. Ezt követően viszont a profilod véglegesen törlésre kerül. Biztosan elküldöd a törlési kérelmet?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="quinary" text @click="removeProfileConfirmation = false">Mégse</v-btn>
          <v-btn color="quinary" style="color: white" @click="removeProfile">Törlés</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      app
    >
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item class="brand-name" disabled>
            <div class="autoclean-title my-2"><strong>AutoClean</strong></div>
          </v-list-item>
          <v-divider></v-divider>
          <hr style="height:10px; visibility:hidden;"/>
          <v-list-item :to="{ name: 'reserve' }" exact>
            <v-list-item-icon class="mr-2 ml-1">
              <v-icon color="secondary">mdi-map-search</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Autómosó kereső</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'favourites' }" exact>
            <v-list-item-icon class="mr-2 ml-1">
              <v-icon color="secondary">mdi-star</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Kedvencek</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'reservations' }" exact>
            <v-list-item-icon class="mr-2 ml-1">
              <v-icon color="secondary">mdi-calendar-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Foglalásaim</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'cars' }" exact>
            <v-list-item-icon class="mr-2 ml-1">
              <v-icon color="secondary">mdi-car-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Autóim</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'profile' }" exact>
            <v-list-item-icon class="mr-2 ml-1">
              <v-icon color="secondary">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profilom</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn color="quinary" block text @click="logout" class="text-capitalize">
            <v-icon class="mr-1" color="quinary">mdi-logout</v-icon>
            Kijelentkezés
          </v-btn>
        </div>
        <div class="pa-2">
          <v-btn color="quinary" style="color: white" block @click="showConfirm" class="text-capitalize">
            <v-icon class="mr-1" color="white">mdi-delete-outline</v-icon>
            Profil törlése
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
    <snackbar></snackbar>

    <span class="its-invisible">{{ getToggleComputed }}</span>
    <v-footer app padless height="56px" class="the-footer" elevation="7" color="white"
    v-if="$route.name !== 'register' && $route.name !== 'login' && $route.name !== 'password-reminder'">
      <v-item-group class="v-btn-toggle toggle-for-buttons" mandatory v-model="toggleValue">
        <v-btn depressed elevation="0" class="five-parts px-0" value="mainpage"
        color="white" :to="{ name: 'reserve' }" exact>
          <v-icon :color="getColorMainPage()">mdi-map-search</v-icon>
          <span :style="{ color: getColorMainPage() }" class="titles-of-buttons mb-0 mt-1">Kereső</span>
        </v-btn>
        <v-btn depressed elevation="0" class="five-parts px-0" value="favourites"
        color="white" :to="{ name: 'favourites' }" exact>
          <v-icon :color="getColorFavourites()">mdi-star</v-icon>
          <span :style="{ color: getColorFavourites() }" class="titles-of-buttons mb-0 mt-1">Kedvencek</span>
        </v-btn>
        <v-btn depressed elevation="0" class="five-parts px-0" value="myreservations"
        color="white" :to="{ name: 'reservations' }" exact>
          <v-icon :color="getColorReservations()">mdi-calendar-clock</v-icon>
          <span :style="{ color: getColorReservations() }" class="titles-of-buttons mb-0 mt-1">Foglalásaim</span>
        </v-btn>
        <v-btn depressed elevation="0" class="five-parts px-0" value="mycars"
        color="white" :to="{ name: 'cars' }" exact>
          <v-icon :color="getColorCars()">mdi-car-multiple</v-icon>
          <span :style="{ color: getColorCars() }" class="titles-of-buttons mb-0 mt-1">Autóim</span>
        </v-btn>
        <v-btn depressed elevation="0" class="five-parts px-0" value="myprofile"
        color="white" :to="{ name: 'profile' }" exact>
          <v-icon :color="getColorProfile()">mdi-account</v-icon>
          <span :style="{ color: getColorProfile() }" class="titles-of-buttons mb-0 mt-1">Profilom</span>
        </v-btn>
      </v-item-group>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    drawer: false,
    group: null,
    toggleValue: '',
    secondaryColor: '#354ea1', // sötétkék
    removeProfileConfirmation: false
  }),
  computed: {
    getToggleComputed () {
      this.getToggleValue()
      return ''
    }
  },
  watch: {
    group () {
      this.drawer = false
    }
  },
  methods: {
    ...mapActions('userData', ['setDeleteProfile']),

    async logout () {
      await this.$store.dispatch('auth/logout')
      this.$router.replace({ name: 'login' })
    },
    showConfirm () {
      this.removeProfileConfirmation = true
    },
    async removeProfile () {
      await this.setDeleteProfile()
      this.removeProfileConfirmation = false
      this.logout()
    },
    getToggleValue () {
      if (this.$route.name === 'reserve') {
        this.toggleValue = 'mainpage'
      } else if (this.$route.name === 'favourites') {
        this.toggleValue = 'favourites'
      } else if (this.$route.name === 'reservations') {
        this.toggleValue = 'myreservations'
      } else if (this.$route.name === 'cars') {
        this.toggleValue = 'mycars'
      } else if (this.$route.name === 'profile') {
        this.toggleValue = 'myprofile'
      }
    },
    getColorMainPage () {
      if (this.$route.name === 'reserve') {
        return this.secondaryColor
      } else {
        return 'black'
      }
    },
    getColorFavourites () {
      if (this.$route.name === 'favourites') {
        return this.secondaryColor
      } else {
        return 'black'
      }
    },
    getColorReservations () {
      if (this.$route.name === 'reservations') {
        return this.secondaryColor
      } else {
        return 'black'
      }
    },
    getColorCars () {
      if (this.$route.name === 'cars') {
        return this.secondaryColor
      } else {
        return 'black'
      }
    },
    getColorProfile () {
      if (this.$route.name === 'profile') {
        return this.secondaryColor
      } else {
        return 'black'
      }
    }
  }
}
</script>

<style lang="sass">
.navigation-title
  color: white
.v-navigation-drawer
  .v-list-item
    color: var(--v-secondary-base) !important
    &--active
      background: var(--v-secondary-base)
      color: white !important
.vue2leaflet-map
  z-index: 1
html
  overflow-y: auto !important
.v-navigation-drawer
  .v-list-item__title,.v-btn__content
    font-size: 20px !important
    font-weight: 400 !important
    padding: 10px
  .v-list-item--active
    .v-list-item__title
      color: white
    .v-list-item__icon
      .v-icon
        color: white !important
.leaflet-popup-content
  width: 228px !important
  margin: 10px 17px !important
.card-title
  color: white
.brand-name
  justify-content: center !important
.the-footer
  display: grid
  gap: 0px
.five-parts
  width: 20%
  height: 100% !important
.titles-of-buttons
  font-size: 12px
.v-footer
  .v-btn__content
    display: flex
    flex-direction: column
    text-transform: none !important
    letter-spacing: .01em !important
  .toggle-for-buttons
    width: 100% !important
    height: 100% !important
  .theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before
    opacity: 0 !important
.its-invisible
  display: none !important
.autoclean-title
  font-size: 24px !important
  color: #f17100
</style>
