<template>
  <v-container class="container" v-if="services.length" fluid>
    <div class="no-reservations" v-if="!reservations.length">
      <div class="label">Nincs még foglalásod.</div>
    </div>
    <div v-else>
      <v-tabs class="mb-2" grow v-model="tab">
        <v-tab>Aktív</v-tab>
        <v-tab>Múltbeli</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-expansion-panels v-if="activeReservations.length" v-model="selected" multiple focusable>
            <v-expansion-panel v-for="reservation in activeReservations" :key="reservation._id" @click="setExpansion(reservation)">
              <div>
                <v-expansion-panel-header class="primary-color px-4" disable-icon-rotate>
                  <div class="service-name">{{ service(reservation.service).name }}</div>
                  <div class="reservation-date pl-1">
                    <div class="black-font-color">{{ formatDate(reservation.date) }}</div>
                    <div class="black-font-color">{{ formatDuration(reservation.date, reservation.duration) }}</div>
                  </div>
                  <template v-slot:actions>
                    <v-icon color="primary" :class="checkExpansion(reservation) ? 'rotate' : ''">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="expansion-content">
                    <div class="contact">
                      <hr style="height:10px; visibility:hidden;"/>
                      <strong class="primary-color mr-2">Állapot:</strong>
                      <span :style="{ color: getStatusColor(reservation.status) }">
                        {{ getStatus(reservation.status) }}
                      </span><br>
                      <div class="pictogram-group my-1">
                        <v-icon dense color="primary">mdi-map-marker-radius</v-icon>
                        <span class="ml-3">
                          <span>{{ getAddressPartOne(service(reservation.service)) }}</span><br>
                          <span>{{ getAddressPartTwo(service(reservation.service)) }}</span>
                        </span>
                      </div>
                      <div class="pictogram-group my-1">
                        <v-icon dense color="primary">mdi-email-mark-as-unread</v-icon>
                        <span class="ml-3">
                          {{ service(reservation.service).contact.email }}
                        </span>
                      </div>
                      <div class="pictogram-group my-1">
                        <v-icon dense color="primary">mdi-phone</v-icon>
                        <span class="ml-3">
                          {{ service(reservation.service).contact.phone }}
                        </span>
                      </div>
                      <v-btn class="direction" @click="openMapWithDirection(service(reservation.service).location)"
                      color="tertiary" style="color: white" small>Útvonaltervezés
                        <v-icon class="pl-1" dense color="white">mdi-open-in-new</v-icon>
                      </v-btn>
                    </div>
                    <div class="cars">
                      <hr style="height:12px; visibility:hidden;"/>
                      <strong class="primary-color">{{ `Autó${Object.keys(reservation.cars).length > 1 ? 'k' : ''} és szolgáltatások:` }}</strong>
                      <hr style="height:2px; visibility:hidden;"/>
                      <div class="car mb-3" v-for="(services, id) in reservation.cars" :key="id">
                        <div v-if="getCarById(id) !== undefined">
                          <v-icon color="primary" left>mdi-car</v-icon>
                          <span>{{ getCarDisplayName(getCarById(id)) }}</span>
                        </div>
                        <div v-if="getCarById(id) !== undefined" class="service-types mt-2 ml-2">
                          <span v-for="(value, service) in services" :key="service">
                            <v-chip class="mr-2">{{ getServiceTypeName(service) }}</v-chip>
                          </span>
                        </div>
                        <div v-if="getCarById(id) === undefined">
                          <v-icon color="primary" left>mdi-car-off</v-icon>
                          <span>( </span><span :style="{color: 'red'}">törölt autó</span><span> )</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-btn @click="cancelConfirm(reservation)" v-if="isCancelVisible(reservation)"
                  class="remove-button" color="quinary" style="color: white" small>Foglalás lemondása</v-btn>
                </v-expansion-panel-content>
              </div>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="no-active" v-else>
            Jelenleg nincs aktív foglalásod.
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-expansion-panels v-if="pastReservations.length" multiple focusable>
            <v-expansion-panel v-for="reservation in pastReservations" :key="reservation._id">
              <v-expansion-panel-header class="primary-color">
                <template v-slot:actions>
                  <v-icon color="primary">
                      $expand
                  </v-icon>
                </template>
                <div class="service-name">{{ service(reservation.service).name }}</div>
                <div class="reservation-date">
                  <div class="black-font-color">{{ formatDate(reservation.date) }}</div>
                  <div class="black-font-color">{{ formatDuration(reservation.date, reservation.duration) }}</div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="expansion-content">
                  <div class="contact">
                    <hr style="height:10px; visibility:hidden;"/>
                    <strong class="primary-color mr-2">Állapot: </strong>
                    <span :style="{ color: getStatusColor(reservation.status) }">
                      {{ getStatus(reservation.status) }}
                    </span><br>
                    <div class="pictogram-group my-1">
                      <v-icon dense color="primary">mdi-map-marker-radius</v-icon>
                      <span class="ml-3">
                        <span>{{ getAddressPartOne(service(reservation.service)) }}</span><br>
                        <span>{{ getAddressPartTwo(service(reservation.service)) }}</span>
                      </span>
                    </div>
                    <div class="pictogram-group my-1">
                      <v-icon dense color="primary">mdi-email-mark-as-unread</v-icon>
                      <span class="ml-3">
                        {{ service(reservation.service).contact.email }}
                      </span>
                    </div>
                    <div class="pictogram-group my-1">
                      <v-icon dense color="primary">mdi-phone</v-icon>
                      <span class="ml-3">
                        {{ service(reservation.service).contact.phone }}
                      </span>
                    </div>
                  </div>
                  <div class="cars">
                    <hr style="height:12px; visibility:hidden;"/>
                    <strong class="primary-color">{{ `Autó${Object.keys(reservation.cars).length > 1 ? 'k' : ''} és szolgáltatások:` }}</strong>
                    <hr style="height:2px; visibility:hidden;"/>
                    <div class="car mb-3" v-for="(services, id) in reservation.cars" :key="id">
                      <div v-if="getCarById(id) !== undefined">
                        <v-icon color="primary" left>mdi-car</v-icon>
                        <span>{{ getCarDisplayName(getCarById(id)) }}</span>
                      </div>
                      <div v-if="getCarById(id) !== undefined" class="service-types mt-2 ml-2">
                        <span v-for="(value, service) in services" :key="service">
                          <v-chip class="mr-2">{{ getServiceTypeName(service) }}</v-chip>
                        </span>
                      </div>
                      <div v-if="getCarById(id) === undefined">
                        <v-icon color="primary" left>mdi-car-off</v-icon>
                        <span>( </span><span :style="{color: 'red'}">törölt autó</span><span> )</span>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="no-active" v-else>
            Nincs múltbeli foglalásod.
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-dialog v-if="isCancelStillAvailable()" v-model="cancelConfirmation" width="350px">
      <v-card>
        <v-card-title class="quinary px-4 pt-2 mb-3 card-title">Lemondás
          <v-icon color="white" right>mdi-cancel</v-icon>
        </v-card-title>
        <v-card-text class="pt-1 pb-3 pl-4">Biztosan lemondod a foglalást?</v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="quinary" text @click="cancelConfirmation = false">Mégse</v-btn>
          <v-btn color="quinary" style="color: white" @click="cancel">Lemondás</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment'

export default {
  data: () => ({
    tab: null,
    cancelConfirmation: false,
    reservationToCancel: null,
    selected: [],
    expansions: [],
    commaPosition: 0
  }),
  computed: {
    ...mapState('reservation', ['reservations']),
    ...mapState('service', ['services']),
    ...mapGetters('car', ['getCarDisplayName', 'getCarById']),
    ...mapGetters('service', ['getServiceTypeName']),

    activeReservations () {
      return this.reservations.filter(({ date }) => moment(date).isSameOrAfter(moment()))
    },
    pastReservations () {
      const sortedReservations = this.reservations.filter(({ date }) => moment(date).isBefore(moment()))
      sortedReservations.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date)
      })
      return sortedReservations
    }
  },
  mounted () {
    this.setExpansions()

    const current = this.$route.params.current
    if (typeof current !== 'undefined') {
      this.selected = [current]
      this.expansions[current].value = !this.expansions[current].value
    }
  },
  watch: {
    activeReservations () {
      this.setExpansions()
    }
  },
  methods: {
    ...mapMutations(['setNotification']),
    ...mapActions('reservation', ['cancelReservation']),

    setExpansions () {
      var number = 0
      for (const thisOne of this.activeReservations) {
        const which = { id: '', value: false }
        which.id = thisOne._id
        this.expansions[number] = which
        number += 1
      }
    },
    setExpansion (reservation) {
      Object.values(this.expansions).some(expansion => {
        if (reservation._id === expansion.id) {
          expansion.value = !expansion.value
        }
      })
    },
    checkExpansion (reservation) {
      for (const thisExp of this.expansions) {
        if (reservation._id === thisExp.id) {
          return thisExp.value
        }
      }
    },
    isCancelVisible (reservation) {
      if (reservation.status === 'rejected') {
        return false
      } else {
        if (moment(reservation.date).isSameOrAfter(moment().add(1, 'hours'))) {
          return true
        } else {
          return false
        }
      }
    },
    isCancelStillAvailable () {
      if (this.reservationToCancel === null) {
        return false
      }
      if (moment(this.reservationToCancel.date).isSameOrAfter(moment().add(1, 'hours'))) {
        return true
      } else {
        this.reservationToCancel = null
        this.cancelConfirmation = false
        this.setNotification('Ezt a foglalást már nincs lehetőséged lemondani.')
        return false
      }
    },
    cancelConfirm (reservation) {
      this.reservationToCancel = reservation
      this.cancelConfirmation = true
    },
    cancel () {
      if (moment(this.reservationToCancel.date).isSameOrAfter(moment().add(1, 'hours'))) {
        this.cancelReservation(this.reservationToCancel)
        this.cancelConfirmation = false
        this.setNotification('Sikeres lemondás!')
      } else {
        this.reservationToCancel = null
        this.cancelConfirmation = false
        this.setNotification('Ezt a foglalást már nincs lehetőséged lemondani.')
      }
    },
    service (id) {
      return this.services.find(({ _id }) => _id === id)
    },
    formatDate (date) {
      return `${moment(date).format('YYYY-MM-DD')} `
    },
    formatDuration (date, duration) {
      return `${moment(date).format('HH:mm')} - ${moment(date).add(duration, 'minutes').format('HH:mm')}`
    },
    openMapWithDirection (location) {
      const platform = navigator.userAgentData?.platform || navigator.platform
      if ((platform.indexOf('iPhone') !== -1) ||
        (platform.indexOf('iPod') !== -1) ||
        (platform.indexOf('iPad') !== -1)) {
        window.open(`maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${location[0]},${location[1]}`)
      } else {
        window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${location[0]},${location[1]}`)
      }
    },
    getStatus (status) {
      switch (status) {
        case 'pending':
          return 'Függőben'
        case 'accepted':
          return 'Elfogadva'
        case 'rejected':
          return 'Elutasítva'
        default:
          return ''
      }
    },
    getStatusColor (status) {
      switch (status) {
        case 'pending':
          return '#edb200'
        case 'accepted':
          return 'green'
        case 'rejected':
          return 'red'
        default:
          return 'black'
      }
    },
    getAddressPartOne (service) {
      this.commaPosition = service.contact.address.indexOf(',')
      return service.contact.address.slice(0, this.commaPosition + 1)
    },
    getAddressPartTwo (service) {
      return service.contact.address.slice(this.commaPosition + 2)
    }
  }
}
</script>

<style lang="sass" scoped>
.rotate
  transform: rotate(-180deg)
.container
  height: 100%
.no-reservations
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
  color: var(--v-primary-base)
.no-active
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 60vh
  color: var(--v-primary-base)
.v-list-item__title
  display: flex
  justify-content: space-between
.reservation-date
  font-size: 16px
.expansion-content
  font-size: 16px
  display: flex
  .contact
    margin-left: 16px
    width: 50%
.primary-color
  color: var(--v-primary-base)
.black-font-color
  color: black !important
.direction
  margin-top: 6px
.card-title
  font-size: 24px !important
.service-name
  font-weight: 500 !important
  font-size: 19px !important
  color: black !important
@media only screen and (max-width: 600px)
  .service-name
    max-width: 170px
    color: black !important
  .contact
    margin-left: 0 !important
    width: 100% !important
  .expansion-content
    display: block
@media only screen and (min-width: 601px)
  .reservation-date *
    display: inline
  .remove-button
    margin-top: 16px
.pictogram-group
  display: flex
  justify-content: left
  align-items: center
</style>
