import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import restClient from './axios'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import { LMap, LTileLayer, LMarker, LPopup, LIcon, LControl } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet'
import SnackBar from './components/SnackBar.vue'
import moment from 'moment'
import { extendMoment } from 'moment-range'

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('l-popup', LPopup)
Vue.component('l-icon', LIcon)
Vue.component('l-control', LControl)
Vue.component('snackbar', SnackBar)

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.use(VueCookies)

Vue.prototype.$axios = restClient
Vue.config.productionTip = false

moment.locale('en')
extendMoment(moment)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
