<template>
  <v-container class="register" fluid>
    <h2 class="mb-4">Regisztráció</h2>
    <img src="@/assets/google.svg" style="cursor: pointer" class="mb-4" width="60" @click="googleLogin" />
    <label>vagy:</label>
    <v-form class="login-form pt-3" ref="form" v-model="valid" @submit.prevent="submit" lazy-validation>
      <div class="name-input">
        <v-text-field type="text" :rules="[rules.nameRequired]" placeholder="Felhasználónév" v-model="name" />
      </div>
      <div class="email-input">
        <v-text-field :rules="[rules.validEmail]" :error-messages="errors.email"
        placeholder="E-mail cím" v-model="email" @input="errors.email = null" validate-on-blur>
        </v-text-field>
      </div>
      <div class="password-input">
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          :rules="rules.passwordRules"
          placeholder="Jelszó"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          v-model="password"
          counter
          hint="A jelszó 8 - 25 karakter hosszúságú kell legyen és tartalmaznia kell kisbetűket,
           legalább egy nagybetűt és legalább egy számot is."
        ></v-text-field>
      </div>
      <v-progress-linear
        class="mt-2 mb-4"
        :height="5"
        :value="password.length > 0 ? calculatePasswordStrength(password) : 0"
        :color="password.length > 0 ? calculatePasswordColor() : 'red accent-4'">
      </v-progress-linear>
      <div class="password-confirm-input">
        <v-text-field
          :type="showPasswordConfirm ? 'text' : 'password'"
          :rules="[rules.passwordsMatch]"
          placeholder="Jelszó újra"
          v-model="passwordConfirm"
          @click:append="showPasswordConfirm = !showPasswordConfirm"
          :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        ></v-text-field>
      </div>
      <div class="continue-buttons-div">
        <v-btn color="quaternary" :loading="loading" class="continue-button mx-auto mt-4"
        style="color: white; width: 100%" type="submit">Folytatás</v-btn>
      </div>
    </v-form>
    <div class="login mt-6">
      Van már fiókod? Akkor <a href="#" @click.prevent="$router.push({ name: 'login' })">jelentkezz be</a> könnyedén.
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      name: '',
      email: '',
      password: '',
      currentPassword: '',
      showPassword: false,
      passwordConfirm: '',
      showPasswordConfirm: false,
      strengthOfPassword: 0,
      loading: false,
      rules: {
        nameRequired: v => !!v || 'Kérjük add meg a felhasználóneved.',
        passwordRules: [
          v => !!v || 'Kérjük add meg a jelszavad.',
          v => !!(v || '').match(/^\S*$/) || 'A jelszó nem tartalmazhat szóközt.',
          v => v.length >= 8 || 'A jelszó legalább 8 karakter hosszú kell legyen.',
          v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/) ||
          'A jelszónak tartalmaznia kell kisbetűket, legalább egy nagybetűt és legalább egy számot is.',
          v => v.length <= 25 || 'A jelszó maximum 25 karakter hosszú lehet.'
        ],
        validEmail: v => {
          const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return emailPattern.test(v) || 'Kérjük adj meg egy valós e-mail címet.'
        },
        passwordsMatch: v => v === this.password || 'A beírt jelszavak nem egyeznek.'
      },
      valid: true,
      errors: {}
    }
  },
  methods: {
    ...mapActions('auth', ['register']),
    async submit () {
      this.errors = {}
      if (!this.$refs.form.validate()) return
      this.loading = true
      const { name, email, password } = this
      try {
        await this.register({ name, email, password })
        this.$router.replace({ name: 'reserve' })
      } catch (e) {
        if (e.response?.data?.message === 'email_already_taken') {
          this.errors.email = ['Ez az e-mail cím már foglalt.']
        }
      }
      this.loading = false
    },
    googleLogin () {
      window.location.href = `${process.env.VUE_APP_API_URL}/auth/google`
    },
    calculatePasswordStrength (thisPassword) {
      if (this.currentPassword !== thisPassword) {
        let passwordStrength = 0

        if (thisPassword.length > 12) {
          passwordStrength += 23
        } else if (thisPassword.length > 11) {
          passwordStrength += 18
        } else if (thisPassword.length > 10) {
          passwordStrength += 15
        } else if (thisPassword.length > 9) {
          passwordStrength += 12
        } else if (thisPassword.length > 7) {
          passwordStrength += 10
        } else if (thisPassword.length > 3) {
          passwordStrength += 5
        } else {
          passwordStrength += 2
        }

        const numberOfUppers = thisPassword.match(/[A-Z]/g)
        if (numberOfUppers) {
          if (numberOfUppers.length > 3) {
            passwordStrength += 35
          } else if (numberOfUppers.length > 2) {
            passwordStrength += 30
          } else if (numberOfUppers.length > 1) {
            passwordStrength += 25
          } else {
            passwordStrength += 15
          }
        }
        const numberOfLowers = thisPassword.match(/[a-z]/g)
        if (numberOfLowers) {
          if (numberOfLowers.length < 5) {
            passwordStrength += 10
          } else {
            passwordStrength += 20
          }
        }
        const numberOfNumbers = thisPassword.match(/[0-9]/g)
        if (numberOfNumbers) {
          if (numberOfNumbers.length > 3) {
            passwordStrength += 35
          } else if (numberOfNumbers.length > 2) {
            passwordStrength += 30
          } else if (numberOfNumbers.length > 1) {
            passwordStrength += 25
          } else {
            passwordStrength += 15
          }
        }
        const numberOfSpecials = thisPassword.match(/[^A-Za-z0-9\s]/g)
        if (numberOfSpecials) {
          if (numberOfSpecials.length > 3) {
            passwordStrength += 25
          } else if (numberOfSpecials.length > 2) {
            passwordStrength += 20
          } else if (numberOfSpecials.length > 1) {
            passwordStrength += 15
          } else {
            passwordStrength += 8
          }
        }

        this.strengthOfPassword = passwordStrength
        this.currentPassword = thisPassword
        return passwordStrength
      } else {
        return this.strengthOfPassword
      }
    },
    calculatePasswordColor () {
      if (this.strengthOfPassword > 90) {
        return 'green accent-4'
      } else if (this.strengthOfPassword > 84) {
        return 'green'
      } else if (this.strengthOfPassword > 74) {
        return 'light-green'
      } else if (this.strengthOfPassword > 65) {
        return 'lime'
      } else if (this.strengthOfPassword > 53) {
        return 'yellow accent-4'
      } else if (this.strengthOfPassword > 45) {
        return 'amber'
      } else if (this.strengthOfPassword > 30) {
        return 'orange'
      } else {
        return 'red'
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.register
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
.login
  font-size: 0.6rem
.login-form
  width: 260px !important
  .v-text-field
    padding-top: 2px !important
.continue-button
  width: 200px !important
.continue-buttons-div
  padding-left: 30px
  padding-right: 30px
</style>
