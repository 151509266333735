import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#f17100', // az alap narancssárga
        secondary: '#354ea1', // sötétkék
        tertiary: '#097edd', // sima kék
        quaternary: '#62c7e3', // világoskék
        quinary: '#D2282B' // piros
      }
    }
  }
})
