<template>
  <v-container class="password-reminder" fluid>
    <h3 class="title mb-7">Jelszó frissítése</h3>
    <label class="info-note">Kérjük add meg az e-mail címedet, amivel regisztráltál az alkalmazásunkba.
    Erre a címre küldünk neked egy 5 számjegyű ellenőrző<br>kódot tartalmazó levelet.</label>
    <v-form class="email-form" ref="emailForm" v-model="valid" @submit.prevent="checkEmail" lazy-validation>
      <div class="email-input">
        <v-text-field class="pt-2" :rules="[rules.validEmail]" :error-messages="errors.email"
        placeholder="E-mail cím" v-model="email" @input="errors.email = null" validate-on-blur>
        </v-text-field>
      </div>
      <div class="continue-div">
        <v-btn color="tertiary" :loading="emailLoading" class="mt-1" width="100%"
        style="color: white" type="submit" :disabled="isEmailDisabled">Küldés</v-btn>
      </div>
    </v-form>
    <label class="info-note pt-5">Az ellenőrző kód 5 percig érvényes. Amennyiben pedig lejárna,
    akkor újra kell küldened az e-mailt, hogy egy új kódot kapjál.
    Add meg az ellenőrző kódot, hogy frissíteni tudd a tárolt jelszavad:</label>
    <div class="position-relative mx-auto mt-3 mb-0" style="max-width: 260px">
      <v-otp-input v-model="verificationCode" length="5" type="number" :disabled="isOtpDisabled" @finish="checkCode">
      </v-otp-input>
      <v-overlay absolute :value="codeLoading">
        <v-progress-circular indeterminate color="tertiary"></v-progress-circular>
      </v-overlay>
    </div>
    <label class="info-note pb-2" :style="{ color: getMessageColor() }">{{ otpMessage }}</label>
    <div class="update-password-div">
      <v-btn color="tertiary" class="mt-1" width="100%" @click="updateDialog = true"
      style="color: white" :disabled="isUpdateDisabled">Jelszó frissítése</v-btn>
    </div>
    <div class="back-to-login mt-8">
      <a href="#" @click.prevent="$router.push({ name: 'login' })">Visszalépés</a> a bejelentkezéshez.
    </div>
    <v-dialog v-model="updateDialog" width="420">
      <v-card>
        <v-form ref="updateForm" @submit.prevent="savePassword" lazy-validation>
          <v-card-title class="tertiary mb-3 card-title">Új jelszó megadása</v-card-title>
          <v-card-text class="pb-3">
            <div class="update-note">Kérjük adj meg egy új jelszót, amit mostantól kezdve használni szeretnél!</div>
            <div>
              <v-text-field
                :type="showPassword ? 'text' : 'password'"
                :rules="rules.passwordRules"
                placeholder="Új jelszó"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-model="password"
                counter
                hint="A jelszó 8 - 25 karakter hosszúságú kell legyen és tartalmaznia kell kisbetűket,
                legalább egy nagybetűt és legalább egy számot is."
              ></v-text-field>
            </div>
            <v-progress-linear
              class="mt-2 mb-4"
              :height="5"
              :value="password.length > 0 ? calculatePasswordStrength(password) : 0"
              :color="password.length > 0 ? calculatePasswordColor() : 'red accent-4'">
            </v-progress-linear>
            <div class="password-confirm-input">
              <v-text-field
                :type="showPasswordConfirm ? 'text' : 'password'"
                :rules="[rules.passwordsMatch]"
                placeholder="Új jelszó újra"
                v-model="passwordConfirm"
                @click:append="showPasswordConfirm = !showPasswordConfirm"
                :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="tertiary" text @click="closeUpdateDialog">Mégse</v-btn>
            <v-btn color="tertiary" type="submit" style="color: white" :loading="saveLoading">Mentés</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import moment from 'moment'

export default {
  data () {
    const timeFormat = 'YYYY-MM-DD HH:mm:ss'
    return {
      email: '',
      password: '',
      currentPassword: '',
      passwordConfirm: '',
      showPassword: false,
      showPasswordConfirm: false,
      strengthOfPassword: 0,
      emailLoading: false,
      rules: {
        validEmail: v => {
          const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return emailPattern.test(v) || 'Kérjük adj meg egy valós e-mail címet.'
        },
        passwordRules: [
          v => !!v || 'Kérjük add meg az új jelszavad.',
          v => !!(v || '').match(/^\S*$/) || 'A jelszó nem tartalmazhat szóközt.',
          v => v.length >= 8 || 'A jelszó legalább 8 karakter hosszú kell legyen.',
          v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/) ||
          'A jelszónak tartalmaznia kell kisbetűket, legalább egy nagybetűt és legalább egy számot is.',
          v => v.length <= 25 || 'A jelszó maximum 25 karakter hosszú lehet.'
        ],
        passwordsMatch: v => v === this.password || 'A beírt jelszavak nem egyeznek.'
      },
      valid: true, //                     ez kell amúgy?
      isEmailDisabled: false,
      isOtpDisabled: true,
      verificationCode: '',
      expectedCode: '',
      codeLoading: false,
      isCodeCorrect: '',
      haveTriedAnyCode: false,
      timeFormat,
      generationTime: moment(),
      expirationTime: moment(),
      isUpdateDisabled: true,
      updateDialog: false,
      saveLoading: false,
      errors: {}
    }
  },
  computed: {
    otpMessage () {
      if (this.expectedCode) {
        if (this.isOtpDisabled && this.isCodeCorrect !== 'true') {
          return 'Az ellenőrző kód már nem érvényes.'
        } else {
          if (this.haveTriedAnyCode && this.isCodeCorrect === 'false') {
            return 'Hibás a megadott ellenőrző kód.'
          } else if (this.haveTriedAnyCode && this.isCodeCorrect === 'true') {
            return 'Ellenőrző kód hitelesítve.'
          } else {
            return ' '
          }
        }
      } else {
        return ' '
      }
    }
  },
  methods: {
    ...mapMutations(['setNotification']),
    ...mapActions('userData', ['passwordReminder', 'sendEmailWithCode', 'updatePassword']),

    async checkEmail () {
      this.errors = {}
      if (!this.$refs.emailForm.validate()) return

      this.emailLoading = true
      const emailMessage = await this.passwordReminder(this.email)
      if (emailMessage === 'no_user_found') {
        this.errors.email = ['Nem található fiók ezzel az e-mail címmel.']
      } else if (emailMessage === 'google_user') {
        this.errors.email = ['Ehhez az e-mail címhez Google-s fiók tartozik.']
      } else if (emailMessage === 'basic_user') {
        this.verificationCode = ''
        this.isCodeCorrect = ''
        this.generateCode()
        const dataToSend = { email: this.email, code: this.expectedCode }
        await this.sendEmailWithCode(dataToSend)
        this.setNotification('Elküldtük a levelet a megadott e-mail címre!')
      }
      this.emailLoading = false
    },
    generateCode () {
      this.isEmailDisabled = true
      this.isOtpDisabled = false
      this.expectedCode = ''
      for (let i = 0; i < 5; i++) {
        this.expectedCode += Math.floor(Math.random() * 10)
      }
      this.generationTime = moment()
      this.expirationTime = moment().add(5, 'minutes')
      setTimeout(() => {
        this.isOtpDisabled = true
        if (this.haveTriedAnyCode && this.isCodeCorrect === 'true') {
          this.isEmailDisabled = true
          this.isUpdateDisabled = false
        } else {
          this.isEmailDisabled = false
        }
      }, 300000)
    },
    checkCode (code) {
      this.isCodeCorrect = ''
      this.haveTriedAnyCode = true
      this.codeLoading = true
      setTimeout(() => {
        if (code === this.expectedCode) {
          this.isCodeCorrect = 'true'
          this.isOtpDisabled = true
          this.isEmailDisabled = true
          this.isUpdateDisabled = false
        } else {
          this.isCodeCorrect = 'false'
        }
        this.codeLoading = false
      }, 2000)
    },
    async savePassword () {
      this.errors = {}
      if (!this.$refs.updateForm.validate()) return

      this.saveLoading = true
      const dataToSend = { email: this.email, password: this.password }
      await this.updatePassword(dataToSend)
      this.saveLoading = false
      this.isUpdateDisabled = true
      this.updateDialog = false
      this.password = ''
      this.currentPassword = ''
      this.passwordConfirm = ''
      this.strengthOfPassword = 0
      this.setNotification('Az új jelszó elmentése sikeresen megtörtént!')
    },
    getMessageColor () {
      if (this.expectedCode) {
        if (this.isOtpDisabled && this.isCodeCorrect !== 'true') {
          return 'red'
        } else {
          if (this.haveTriedAnyCode && this.isCodeCorrect === 'false') {
            return 'red'
          } else if (this.haveTriedAnyCode && this.isCodeCorrect === 'true') {
            return 'green'
          }
        }
      }
    },
    calculatePasswordStrength (thisPassword) {
      if (this.currentPassword !== thisPassword) {
        let passwordStrength = 0

        if (thisPassword.length > 12) {
          passwordStrength += 23
        } else if (thisPassword.length > 11) {
          passwordStrength += 18
        } else if (thisPassword.length > 10) {
          passwordStrength += 15
        } else if (thisPassword.length > 9) {
          passwordStrength += 12
        } else if (thisPassword.length > 7) {
          passwordStrength += 10
        } else if (thisPassword.length > 3) {
          passwordStrength += 5
        } else {
          passwordStrength += 2
        }

        const numberOfUppers = thisPassword.match(/[A-Z]/g)
        if (numberOfUppers) {
          if (numberOfUppers.length > 3) {
            passwordStrength += 35
          } else if (numberOfUppers.length > 2) {
            passwordStrength += 30
          } else if (numberOfUppers.length > 1) {
            passwordStrength += 25
          } else {
            passwordStrength += 15
          }
        }
        const numberOfLowers = thisPassword.match(/[a-z]/g)
        if (numberOfLowers) {
          if (numberOfLowers.length < 5) {
            passwordStrength += 10
          } else {
            passwordStrength += 20
          }
        }
        const numberOfNumbers = thisPassword.match(/[0-9]/g)
        if (numberOfNumbers) {
          if (numberOfNumbers.length > 3) {
            passwordStrength += 35
          } else if (numberOfNumbers.length > 2) {
            passwordStrength += 30
          } else if (numberOfNumbers.length > 1) {
            passwordStrength += 25
          } else {
            passwordStrength += 15
          }
        }
        const numberOfSpecials = thisPassword.match(/[^A-Za-z0-9\s]/g)
        if (numberOfSpecials) {
          if (numberOfSpecials.length > 3) {
            passwordStrength += 25
          } else if (numberOfSpecials.length > 2) {
            passwordStrength += 20
          } else if (numberOfSpecials.length > 1) {
            passwordStrength += 15
          } else {
            passwordStrength += 8
          }
        }

        this.strengthOfPassword = passwordStrength
        this.currentPassword = thisPassword
        return passwordStrength
      } else {
        return this.strengthOfPassword
      }
    },
    calculatePasswordColor () {
      if (this.strengthOfPassword > 90) {
        return 'green accent-4'
      } else if (this.strengthOfPassword > 84) {
        return 'green'
      } else if (this.strengthOfPassword > 74) {
        return 'light-green'
      } else if (this.strengthOfPassword > 65) {
        return 'lime'
      } else if (this.strengthOfPassword > 53) {
        return 'yellow accent-4'
      } else if (this.strengthOfPassword > 45) {
        return 'amber'
      } else if (this.strengthOfPassword > 30) {
        return 'orange'
      } else {
        return 'red'
      }
    },
    closeUpdateDialog () {
      this.updateDialog = false
      this.password = ''
      this.currentPassword = ''
      this.passwordConfirm = ''
      this.strengthOfPassword = 0
    }
  }
}
</script>

<style lang="sass" scoped>
.password-reminder
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
.back-to-login
  font-size: 0.6rem
.email-form
  width: 300px !important
.info-note
  font-size: 14.4px
  padding-left: 15px
  padding-right: 13px
  max-width: 350px
.title
  font-size: 28px !important
  font-weight: 600
.password-input
  display: flex
  width: 300px
.continue-div
  padding-left: 80px
  padding-right: 80px
.update-password-div
  width: 300px
  padding-left: 20px
  padding-right: 20px
.position-relative
  position: relative
.update-note
  font-size: 16px
  line-height: 1.2rem
</style>
