import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import service from './modules/service'
import car from './modules/car'
import reservation from './modules/reservation'
import userData from './modules/userData'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['selectedService', 'selectedCars']
    })
  ],
  state: {
    notification: '',
    selectedService: null,
    selectedCars: {} // and services...
  },
  mutations: {
    setSelectedService (state, service) {
      state.selectedService = service
    },
    setNotification (state, text) {
      state.notification = text
    },
    setSelectedCars (state, cars) {
      state.selectedCars = cars
    }
  },
  actions: {},
  modules: {
    auth,
    service,
    car,
    reservation,
    userData
  }
})
