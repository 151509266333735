import axios from 'axios'
import store from './store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
})

instance.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 500) {
    store.commit('setNotification', 'Szerver hiba, kérjük próbálkozz újra később.')
  }
  return Promise.reject(error)
})

export default instance
